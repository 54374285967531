import { reactive, ref, shallowReactive } from 'vue';
import Payment from '@/api/Payment';

export default () => {
  const title = ref('');
  const info = ref('');
  const loading = ref(false);
  const rows = shallowReactive([]);

  const fetcher = (id) => {
    loading.value = true;
    return Payment.nonAuthPlan({ planId: id })
      .then((res) => {
        if (res.code === 1) {
          title.value = res.data.title;
          info.value = res.data.info;
          if (res.data.rows.length) {
            rows.splice(0, rows.length, ...res.data.rows);
          }
        }
        console.log(rows);
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const reset = () => {
    rows.length = 0;
  };

  return { title, info, rows, fetcher, loading, reset };
};
