<template>
  <div>
    <div
      class="container py-12 flex space-x-8 md:flex-col md:space-x-0 md:space-y-8"
    >
      <div class="flex-grow space-y-8">
        <div class="content-wrap">
          <h2 class="content-title">填寫電子郵件</h2>
          <div class="content">
            <div
              class="flex items-center space-x-4 md:block md:space-x-0 md:space-y-4"
            >
              <el-form ref="emailFormRef" :model="invoiceData.form">
                <el-form-item
                  label="電子郵件"
                  prop="email"
                  :rules="rules.email"
                >
                  <el-input v-model="invoiceData.form.email" type="email" />
                </el-form-item>
              </el-form>
            </div>
            <p class="text-gray mt-4">
              下一步將連至第三方金流服務進行刷卡，你所有的交易資訊皆獲得安全保護。
            </p>
          </div>
        </div>

        <div class="content-wrap">
          <div class="content-title">發票選項</div>
          <div class="content">
            <p v-if="isFree" class="text-danger">目前消費金額不需開立發票</p>
            <p v-else class="text-gray">
              以下資訊只用於開立發票，並不會在其他頁面顯示。發票一經開立後不可更改，請確認資訊是否都填寫正確喔！
              <br />
              （ <span class="text-danger">*</span> 為必填欄位）
            </p>
            <InvoiceForm :disabled="isFree"></InvoiceForm>
          </div>
        </div>
      </div>
      <div class="w-[330px] flex-shrink-0 md:w-full">
        <div class="content-wrap h-full">
          <div class="content-title">訂單明細</div>
          <div class="content">
            <!-- 明細 -->
            <Detail></Detail>
            <hr class="my-4" />
            <!-- 優惠卷 -->
            <div class="mt-16 w-full flex">
              <el-button
                type="primary"
                size="large"
                class="flex-grow"
                @click="confirm"
                >確認付款</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="checkoutDialog.state.show"
      title="結帳確認"
      center
      :custom-class="isFree ? 'w-[400px]' : ''"
    >
      <div
        v-loading="submitting"
        class="flex space-x-2 md:flex-col md:space-x-0 justify-items-stretch"
      >
        <div class="w-1/2 flex-grow md:w-full">
          <div class="bg-primary-light p-4 rounded">
            <Detail />
          </div>
        </div>
        <div v-if="!isFree" class="w-1/2 md:w-full">
          <div
            class="p-4 h-full flex-grow bg-background rounded leading-loose md:flex-col md:space-y-4"
          >
            <InvoiceInfo />
          </div>
        </div>
      </div>
      <div class="flex space-x-4 justify-center mt-6">
        <el-button size="large" @click="back">返回修改</el-button>
        <el-button type="primary" size="large" @click="submit"
          >確認送出</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { provide, computed, ref } from 'vue';
import { ElForm, ElFormItem, ElInput, ElDialog, ElMessage } from 'element-plus';
import Coupon from '@/modules/coupon/Checkout.vue';
import InvoiceForm from '@/modules/invoice/Index.vue';
import InvoiceInfo from '@/modules/invoice/Info.vue';
import Detail from '@/modules/shop/Detail.vue';

import useShop from '@/views/qb/useShop';
import usePlans from '@/views/qb/usePlans';

import useInvoice from '@/modules/invoice/useInvoice';
import { useRoute, useRouter } from 'vue-router';
import useDialog from '@/hook/useDialog';
import Payment from '@/api/Payment';
import Regex from '@/utils/Regex';
const router = useRouter();

const route = useRoute();

const invoiceData = useInvoice();
provide('invoiceData', invoiceData);

const checkoutDialog = useDialog();

const plansData = usePlans();
// provide('plansData', plansData);

const shopData = useShop({
  plans: plansData,
  invoice: invoiceData,
});
const { form, submit, clear, detail, submitting } = shopData;
provide('shopData', shopData);

plansData.fetcher(route.query.planId);

// if (!form.planId) {
//   clear();
//   ElMessage('資料不完整，請重新選擇方案');
//   router.push({ name: 'Shop.Home' });
// }

const back = () => {
  checkoutDialog.toggle({ show: false });
};

const emailFormRef = ref(null);
const rules = {
  email: {
    validator: async (rule, value, callback) => {
      if (!value) {
        callback(new Error('請輸入email'));
        return;
      } else if (!Regex.email.test(value)) {
        callback(new Error('請輸入正確的email格式!'));
        return;
      }
    },
  },
};
const isFree = computed(() => {
  return detail.total <= 0;
});
const confirm = async () => {
  try {
    if (!isFree.value) {
      await invoiceData.invoiceFormRef.value.validate();
    }
    await emailFormRef.value.validate();
    checkoutDialog.toggle({ show: true });
  } catch (error) {
    console.log(error);
  }
};
</script>

<style lang="sass" scoped>
.content-wrap
     @apply rounded shadow border bg-white
.content-title
    @apply px-4 py-6 text-xl px-4 py-6 border-b border-black
.content
    @apply px-4 py-6
</style>
