import { reactive, ref, inject, computed } from 'vue';
import _ from 'lodash';
import { TYPES, CARRIER_TYPES, DONATES } from '@/modules/invoice/Config.js';
export default () => {
  const auth = inject('auth');
  const defaultForm = {
    type: 'B2C',
    email: auth.user.accountType === 'native' ? auth.user.email : '',
    Category: 'B2C',
    BuyerName: '',
    BuyerUBN: '',
    BuyerAddress: '',
    CarrierType: '',
    CarrierNum: '',
    LoveCode: null,
  };
  const form = reactive({
    ...defaultForm,
    // PrintFlag: 'Y',
    // Y=索取(營業人可於本平台列印此發票)
    // N=不索取(買受人以載具索取發票或捐贈)
    // 1.當 Category=B2B 時，則此參數必填 Y。
    // 2.當 Category=B2C 時，若 CarrierType、
    // LoveCode 參數皆為空值，則此參數必填
    // Y。
  });
  const reset = () => {
    Object.assign(form, { ...defaultForm });
  };
  const updateCategory = () => {
    form.Category = form.type === 'B2B' ? 'B2B' : 'B2C';
  };

  const rules = reactive({
    BuyerName: [
      { required: true, message: '請輸入買受人名稱', trigger: 'blur' },
      { max: 30, message: '不得超過30個字元', trigger: 'change' },
    ],
    BauyerName: [
      { required: true, message: '請輸入買受人名稱', trigger: 'blur' },
      { max: 30, message: '不得超過30個字元', trigger: 'change' },
    ],
    BuyerAddress: [
      { required: true, message: '請輸入地址', trigger: 'blur' },
      { max: 100, message: '不得超過100個字', trigger: 'change' },
    ],
  });

  const invoiceFormRef = ref(null);
  const getForm = () => {
    const fields = TYPES.find((x) => x.key === form.type)['fields'];
    let _form = {};
    Object.keys(form).forEach((key) => {
      if (fields.indexOf(key) > -1) {
        _form[key] = form[key];
      }
    });
    _form.email = form.email;

    return _form;
  };

  const getInfo = () => {
    const cat = TYPES.find((x) => x.key === form.type)['name'];
    let text = '';
    text = `${cat}`;
    return text;
  };
  const info = computed(() => {
    const cat = TYPES.find((x) => x.key === form.type)['name'];

    const _form = getForm();
    let text = '';

    if (_form['CarrierType']) {
      const carrier = CARRIER_TYPES.find((x) => x.key === _form['CarrierType']);
      if (carrier) {
        text = `${carrier.name}  ${_form['CarrierNum']}`;
      }
      text = `${carrier.name}  ${_form['CarrierNum']}`;
    } else if (_form['BuyerUBN']) {
      text = `統一編號  ${_form['BuyerUBN']}`;
    } else if (_form['BuyerUBN']) {
      text = `統一編號  ${_form['BuyerUBN']}`;
    } else if (_form['BuyerUBN']) {
      text = `統一編號  ${_form['BuyerUBN']}`;
    } else if (_form['LoveCode']) {
      const unit = DONATES.find((x) => x.key === _form['LoveCode']);
      const _unit = unit
        ? unit
        : {
            name: DONATES[DONATES.length - 1].name,
            key: _form['LoveCode'],
          };

      text = `愛心捐贈/ ${_unit.name}  ${_unit.key}`;
    }

    return text;
  });

  return {
    TYPES,
    invoiceFormRef,
    form,
    rules,
    updateCategory,
    getForm,
    getInfo,
    info,
    reset,
  };
};
