import {
  ref,
  reactive,
  shallowReactive,
  watch,
  watchEffect,
  inject,
} from 'vue';
import Payment from '@/api/Payment';
import Storage from '@/utils/Storage';
import _ from 'lodash';
import { postForm } from '@/utils/';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import MessageBox from '@/components/messageBox/Index';
export default ({ plans, invoice }) => {
  const formRef = ref();
  const auth = inject('auth');
  const router = useRouter();
  const defaultForm = {
    planId: '',
    // email: auth.user.accountType === 'native' ? auth.user.email : '',
    price: 0,
    qPonIds: [],
  };
  const form = reactive({
    ...defaultForm,
  });
  const reset = () => {
    Object.assign(form, { ...defaultForm });
  };
  const detail = shallowReactive({
    title: 'none',
    info: '',
    subtotal: 0,
    discount: 0,
    total: 0,
  });

  watchEffect(() => {
    detail.title = plans.title.value;
    detail.info = plans.info.value;
    const plan = plans.rows[0];

    if (plan) {
      detail.subtotal = plan.payPrice;
      form.planId = plan.id;
    }
  });

  watchEffect(() => {
    detail.total = Math.max(detail.subtotal - detail.discount, 0);
    form.price = detail.total;
    // detail.total = 0;
  });

  const key = auth.user.accountId + '_checkout';
  Storage.sync(key, form);
  Storage.sync(key, invoice.form);

  watch(
    [() => _.cloneDeep(form), () => _.cloneDeep(invoice.form)],
    (val, old) => {
      if (!val || _.isEqual(val, old)) return;
      Storage.set(key, { ...val[0], ...val[1] });
    },
    { deep: true, immediate: true },
  );
  const clear = () => {
    invoice.reset();
    reset();
  };

  const submitting = ref(false);

  const submit = () => {
    submitting.value = true;
    let params = {
      planId: form.planId,
      qPonIds: form.qPonIds,
      price: form.price,
    };

    if (detail.total > 0) {
      params = {
        ...params,
        ...invoice.getForm(),
      };
    }
    return Payment.nonAuthPurchase({ ...params }).then((res) => {
      if (res.code === 1) {
        const orderId = res.data.orderId;
        if (res.data.free == 1) {
          freePay(orderId).then((res) => {
            if (res.code === 1) {
              router.push({
                name: 'Qb.Waiting',
                query: { orderId: orderId },
              });
            }
          });
          clear();
        } else if (res.data.purchaseInfo?.postUrl) {
          clear();
          postForm(res.data.purchaseInfo.postUrl, { ...res.data.purchaseInfo });
        } else {
          ElMessage('缺少相關資料');
        }
      } else {
        // let link = { name: '購買紀錄', route: 'Account.Billing' };
        // if (res.status === 409) {
        //   link = { name: '會員方案紀錄', route: 'Account.History' };
        // }
        // else if (res.status >= 400) {
        //   link = { name: '方案購買紀錄', route: 'Account.Billing' };
        // }
        MessageBox.confirm('結帳流程出問題了', res.message, 'alert', {
          showClose: true,
          distinguishCancelAndClose: true,
          confirmButtonText: '回到頁面',
          // cancelButtonText: link.name,

          callback: (params, instance) => {
            if (params === 'confirm') {
              router.push({ name: 'Qb.Checkout' });
            } else if (params === 'cancel') {
              router.push({ name: 'Qb.Checkout' });
            }
          },
        });
      }

      submitting.value = false;
      return res;
    });
  };

  const setPlanId = (id) => {
    form.planId = id;
  };

  const freePay = (id) => {
    return Payment.freeCheckout({ orderId: id }).then((res) => {
      if (res.code === 1) {
        ElMessage('購買成功');
      } else {
        ElMessage('發生錯誤');
      }
      return res;
    });
  };

  return {
    formRef,
    submit,
    form,
    detail,
    clear,
    setPlanId,
    invoice,
    submitting,
  };
};
